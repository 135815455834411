import { JwtHelperService } from '@auth0/angular-jwt';
import decode from 'jwt-decode';
import diacritic from 'diacritic';
import { environment } from '../../../environments/environment';
import { UploadProxy } from '../../models/proxys/upload.proxy';
import { UserProxy } from '../../models/proxys/user.proxy';

import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ListFilterParams } from '../../models/interfaces/list-filter-params.interface';
import { RolesEnum } from '../../models/enum/roles.enum';
import { BaseReportsFiltersParams } from '../../models/interfaces/report-filters-params/base-report-filters-params.interface';
import { UserAmountsInterface } from '../../models/interfaces/report-filters-params/user/user-amounts.interface';
import { ReportFilterDateRange } from '../../models/interfaces/report-filters-params/report-filters-date-range.interface';
import { UserReportFiltersParams } from '../../models/interfaces/report-filters-params/user/user-report-filters-params.interface';
import { UserReportUserData } from '../../models/interfaces/report-filters-params/user/user-report-user-data.interface';
import { AgeIntervalEnum } from '../../models/enum/age-interval.enum';

export async function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function addAccentOptions(str: string): string[] {
  const baseStr = diacritic.clean(str);
  const accentOptions = {
    'a': ['á', 'ã'],
    'e': ['é', 'ê'],
    'i': ['í'],
    'o': ['ó', 'ô', 'õ'],
    'u': ['ú'],
    'c': ['ç'],
  };

  const result = [str];
  for (let i = 0; i < baseStr.length; i++) {
    const baseChar = baseStr.charAt(i);
    if (accentOptions.hasOwnProperty(baseChar)) {
      const options = accentOptions[baseChar];
      const newResult: string[] = [];

      for (let j = 0; j < options.length; j++) {
        const option = options[j];

        for (let k = 0; k < result.length; k++) {
          const baseOption = result[k].substring(0, i) + option + result[k].substring(i + 1);
          newResult.push(baseOption);
        }
      }
      result.push(...newResult);
    }
  }

  return result;
}

export function compareAccentOptionsObject(str: string): any {
  const arrayOptions = addAccentOptions(str);

  const arrayrMappedWithContL = arrayOptions.map(option => {
    return { $contL: option };
  });

  return {
    $or: arrayrMappedWithContL,
  };
}

export function getCrudErrors({ status, error }: any): string[] {
  if (status >= 500 && status <= 599)
    return ['Ocorreu um erro interno, por favor, tente novamente.'];

  if (!Array.isArray(error.message)) {
    if (typeof error.message === 'string' && error.message.includes('Cannot'))
      return ['A rota especificada não foi encontrada, por favor, contate os administradores se o erro persistir.'];

    return [error.message || 'Ocorreu um erro inesperado, por favor, contate os administradores se o erro persistir.'];
  }

  if (error.message.every(message => typeof message === 'string'))
    return error.message;

  // @ts-ignore
  return error.message.map(({ constraints }) => constraints && Object.values(constraints) || [])
    .reduce((acc, actual) => [...acc, ...actual] as string[]);
}

export function processBase64Image(file: any, onLoad: (base64: string) => void): void {
  if (!file)
    return;

  const reader = new FileReader();

  reader.onloadend = () => {
    if (typeof reader.result !== 'string')
      return;

    onLoad(reader.result);
  };

  reader.readAsDataURL(file);
}

export async function processBase64ImageAsync(file: any): Promise<string> {
  return new Promise(resolve => {
    processBase64Image(file, resolve);
  });
}

export async function uploadImage(http: HttpAsyncService, base64WithData: string): Promise<[boolean, string]> {
  const base64 = base64WithData.split(',')[1];
  const mimeType = base64WithData.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

  const { error, success } = await http.post<UploadProxy>('/media/upload/image', { base64, mimeType });

  if (error)
    return [false, getCrudErrors(error)[0]];

  return [true, success.url];
}

/**
 * Método que verifica se o usuário tem permissão de logar no dashboard
 *
 * @param token As informações do token de autenticação
 */
export function hasPermissionToLogin(token: string): boolean {
  const jwt = new JwtHelperService();

  if (!token || jwt.isTokenExpired(token))
    return false;

  const tokenPayload: { roles: string } = decode(token);

  if (typeof tokenPayload.roles !== 'string')
    return false;

  return hasRole(tokenPayload.roles, RolesEnum.ADMIN)
    || hasRole(tokenPayload.roles, RolesEnum.OWNER)
    || hasRole(tokenPayload.roles, RolesEnum.ADMIN_UQR)
    || hasRole(tokenPayload.roles, RolesEnum.VISITOR);
}

/**
 * Método que diz se o usuário possui uma permissão em específico
 *
 * @param roles As permissões do usuário
 * @param targetRole A permissão que ele está verificando
 */
export function hasRole(roles: string, targetRole: string): boolean {
  const rolesArray = typeof roles === 'string' && roles.split('|') || '';

  return rolesArray.includes(targetRole);
}

/**
 * Método que verifica se o usuário logado atualmente é owner
 */
export function isUserOwner(): boolean {
  return hasRole(getCurrentUser()?.permissions || 'none', RolesEnum.OWNER);
}

/**
 * Método que verifica se o usuário logado atualmente é um admin
 */
export function isUserAdmin(): boolean {
  return hasRole(getCurrentUser()?.permissions || 'none', RolesEnum.ADMIN);
}

/**
 * Método que verifica se o usuário logado atualmente é um admin uqr
 */
export function isUserAdminUQR(): boolean {
  return hasRole(getCurrentUser()?.permissions || 'none', RolesEnum.ADMIN_UQR);
}

export function isUserVisitor(): boolean {
  return hasRole(getCurrentUser()?.permissions || 'none', RolesEnum.VISITOR);
}

/**
 * Método que retorna as informações do usuário atualmente logado
 */
export function getCurrentUser(): UserProxy | null {
  try {
    return JSON.parse(localStorage.getItem(environment.keys.user));
  } catch (e) {
    return null;
  }
}

export function capitalizeFirstLetter(content: string): string {
  content = content.toLowerCase();
  return content.charAt(0).toUpperCase() + content.slice(1);
}

export function subtractHours(date: Date, hours: number): Date {
  date = new Date(date);
  date.setHours(date.getHours() - hours);

  return date;
}

export function addHours(date: Date, hours: number): Date {
  date = new Date(date);
  date.setHours(date.getHours() + hours);

  return date;
}

export function setQueryParams(activatedRoute: ActivatedRoute, router: Router, filterParams: ListFilterParams): void {
  const queryParams: Params = {
    status: filterParams.status,
    searchText: filterParams.searchText,
    organizationIds: filterParams.organizationIds,
    organizationId: filterParams.organizationId,
    currentPage: filterParams.currentPage,
    roles: filterParams.roles,
  };

  router.navigate(
    ['.'],
    {
      relativeTo: activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    },
  );
}

export function initializeReportBaseFilterParams(): BaseReportsFiltersParams {
  return {
    organizationsIds: [],
    dates: getCurrentMonthDateRange(),
  };
}

export function getCurrentMonthDateRange(): ReportFilterDateRange {
  let start = new Date();
  let end = new Date();

  start.setDate(1);

  start = new Date(start.toDateString());
  end = new Date(end.toDateString());

  return {
    start,
    end,
  };
}

export function initializeUserReport(): UserReportFiltersParams {
  return {
    ...initializeReportBaseFilterParams(),
    userData: getEmptyUserDataFilters(),
  };
}

export function getEmptyUserDataFilters(): UserReportUserData {
  return {
    ageInterval: [],
    city: [],
    educationLevel: [],
    gender: [],
    patentPoints: [],
    permissions: [],
    state: [],
    status: [],
  };
}

export function initializeUsersAmounts(): UserAmountsInterface {
  return {
    mostWatchedHours: [],

    devicesTable: [],

    gender: [],

    education: [],
    totalEducation: 0,

    location: [],
    totalLocation: 0,

    ageInterval: [],
    totalAgeInterval: 0,
    ageIntervalGraphData: [],

    sessionIQR: { users: [] },
    totalSessionTimeIQR: 0,

    sessionUQR: { users: [] },
    totalSessionTimeUQR: 0,
  };
}

export function calculateReportTotalAmount(results: any[]): number {
  if (results.length === 0)
    return 0;

  return results.reduce<number>((prev, curr) => prev + +curr.amount, 0);
}

export function initializeAgeIntervalGraphData(): any[] {
  return [
    { 
      count: 0,
      interval: AgeIntervalEnum.TWENTY,
      percentage: 0
    },
    { 
      count: 0,
      interval: AgeIntervalEnum.THIRTY,
      percentage: 0
    },
    { 
      count: 0,
      interval: AgeIntervalEnum.FOURTY,
      percentage: 0
    },
    { 
      count: 0,
      interval: AgeIntervalEnum.FIFTY,
      percentage: 0
    },
    { 
      count: 0,
      interval: AgeIntervalEnum.SIXTY,
      percentage: 0
    },
    { 
      count: 0,
      interval: AgeIntervalEnum.MORE_THAN_SIXTY,
      percentage: 0
    },
  ]
}
