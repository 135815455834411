export enum RolesEnum {
  ANONYMOUS = 'anonymous',
  VISITOR = 'visitor',
  OWNER = 'owner',
  USER = 'user',
  LEADER = 'leader',
  MKT = 'mkt',
  MQL = 'mql',
  ADMIN = 'admin',
  ADMIN_UQR = 'admin_uqr'
}

export enum SelectableRolesEnum {
  VISITOR = 'visitor',
  OWNER = 'owner',
  USER = 'user',
  LEADER = 'leader',
  MKT = 'mkt',
  MQL = 'mql',
  ADMIN_UQR = 'admin_uqr',
}

export const roles: Record<RolesEnum, string> = {
  [RolesEnum.ANONYMOUS]: 'Anônimo',
  [RolesEnum.VISITOR]: 'Visitante',
  [RolesEnum.OWNER]: 'Diretor',
  [RolesEnum.USER]: 'Corretor',
  [RolesEnum.LEADER]: 'Líder',
  [RolesEnum.MKT]: 'MKT',
  [RolesEnum.MQL]: 'MQL',
  [RolesEnum.ADMIN]: 'Administrador',
  [RolesEnum.ADMIN_UQR]: 'Administrador UQR'
}

export const selectableRoles: Record<SelectableRolesEnum, string> = {
  [RolesEnum.VISITOR]: 'Visitante',
  [RolesEnum.OWNER]: 'Diretor',
  [RolesEnum.USER]: 'Corretor',
  [RolesEnum.LEADER]: 'Líder',
  [RolesEnum.MKT]: 'MKT',
  [RolesEnum.MQL]: 'MQL',
  [RolesEnum.ADMIN_UQR]: 'Administrador UQR',
}

